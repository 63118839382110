.heading {
  .heading-heading {
    h1 {
      font-size: 36px;
      letter-spacing: -1px;
      line-height: 1.1;
      font-weight: 700;
      font-family: $font-family-heading;
      @include media-breakpoint-up(md) {
        font-size: 48px;
        line-height: 1;
        width: 80%;
        letter-spacing: -2px;
      }
      strong {
        font-style: normal;
        text-decoration-line: underline;
        text-decoration-color: antiquewhite;
      }
    }
  }
  .heading-description {
    width: 90%;
    p {
      font-size: 22px;
      letter-spacing: -1px;
      line-height: 1.24;
      font-family: $font-family-base;
      color: var(--color-base-text-2);
      @include media-breakpoint-up(md) {
        font-size: 26px;
        letter-spacing: 1px;
        line-height: 1.4;
      }
    }
  }
  .heading-heading + .heading-description {
    margin-top: 20px;
    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
  }
  .heading-badge {
    font-family: $font-family-monospace;
    color: var(--color-primary);
  }
  .heading-image {
    margin-top: 30px;
    img {
      max-width: 100%;
    }
  }
}
