.card-post {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  overflow: hidden;
  position: relative;
  border-radius: $card-border-radius;
  .card-thumbnail {
    border-radius: $card-border-radius;
    overflow: hidden;
    a {
      display: block;
    }
    img {
      aspect-ratio: 16 / 9;
      width: 100%;
      object-fit: contain;
      &:hover {
        opacity: 0.8;
      }
    }
    &.card-thumbnail-large {
      @include media-breakpoint-up(md) {
        max-height: 260px;
      }
      @include media-breakpoint-up(xl) {
        max-height: 300px;
      }
    }
  }
  .card-content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .card-title {
    h2,
    h3 {
      font-size: 26px;
      font-weight: 500;
      line-height: 1.3;
      letter-spacing: -0.1px;
      margin-bottom: 6px;
    }
    a {
      color: var(--color-base-text);
    }
  }
  .card-description {
    p {
      line-height: 1.5;
      font-weight: 400;
      font-size: 16px;
      color: var(--color-base-text-2);
      margin: 0;
    }
  }
  .card-title + .card-description {
    margin-top: 0;
  }
  .card-readmore {
    margin-top: auto;
    line-height: 1;
    i {
      margin-left: 5px;
      margin-top: 4px;
    }
  }
  .card-date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    color: var(--color-base-text-2);
    font-size: 15px;
  }
  .card-categories {
    display: inline-flex;
    font-size: 12px;
    font-family: $font-family-monospace;
    > a {
      background-color: var(--color-base-bg-2);
      padding: 3px 7px;
      border-radius: 2px;
      margin-right: 10px;
    }
  }
  .card-image {
    width: 60px;
    margin-bottom: 10px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .card-meta {
    display: flex;
    margin-bottom: 10px;
    .on {
      margin: 0 4px 0 4px;
    }
  }
  .card-authors {
    margin-right: 7px;
    display: flex;
    .author {
      .author-image {
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  // Tweaks for dark mode
  html[data-mode="dark"] & {
  }
}
.card-post.card-row {
  border-radius: 0;
  box-shadow: none;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  .card-thumbnail {
    @include media-breakpoint-up(md) {
      min-height: auto;
      padding: 0;
      margin-bottom: 10px;
      width: 300px;
      margin-right: 30px;
      margin-bottom: 0;
      a {
        background-color: var(--color-base-200);
      }
    }
  }
  .card-content {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
}
.card-post.card-list {
  border-radius: 0;
  box-shadow: none;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  .card-thumbnail {
    padding: 0;
    margin-bottom: 10px;
    margin-right: 16px;
    margin-bottom: 0;
    height: 60px;
    width: 80px;
    img {
      object-fit: cover;
    }
  }
  .card-meta {
    margin-bottom: 2px;
  }
  .card-title {
    margin-bottom: 4px;
    font-weight: 600;
    h3 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.3;
      margin: 0;
    }
    a {
      color: var(--color-base-text);
    }
  }
  .card-content {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
  .card-authors {
    display: none;
  }
  .card-description {
    display: none;
  }
  .card-categories {
    display: none;
  }
}
.card-post.card-full {
  .card-thumbnail {
    @include media-breakpoint-up(md) {
      min-height: auto;
      padding: 0;
      margin-bottom: 10px;
      width: auto;
      max-height: none;
      margin-right: 0;
      margin-bottom: 30px;
      a {
        background-color: var(--color-base-200);
      }
    }
  }
  .card-content {

  }
  .card-categories {
    margin-top: 20px;
  }
  .card-meta {
    margin-top: 20px;
    order: 4;
  }
  .card-title {
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 1.4;
    flex-basis: 50%;
    h3 {
      @include media-breakpoint-up(lg) {
        font-size: 36px;
      }
    }
    a {
      color: var(--color-base-text);
    }
  }
  .card-description {
    flex-basis: 40%;
  }
  .card-content {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
}
